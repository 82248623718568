export const clientTypeOptions = [
  { value: "in-person", name: "In-person client" },
  { value: "online", name: "Online client" },
  { value: "hybrid", name: "Hybrid client" },
];

export const feedbackFormsOptions = [
  { id: "b5058b66-e54e-4e86-a758-55eb9631681e", name: "In-person client" },
  { id: "b5058b66-e54e-4e86-a758-55eb9631681e", name: "Online client" },
  { id: "b5058b66-e54e-4e86-a758-55eb9631681e", name: "Hybrid client" },
];

export const filterOptions = [
  { value: "new-lead", name: "New Leads" },
  { value: "onboarding-sent", name: "Onboarding Sent" },
  { value: "onboarding-complete", name: "Onboarding Complete" },
  { value: "booking-today", name: "Booking Today" },
  { value: "program-due", name: "Program Due" },
  { value: "outstanding-client-interaction", name: "Outstanding Client Interaction" },
];

export const PLAN_STATUSES = {
  newLead: { value: "new-lead", displayName: "New Lead" },
  onboardingSent: { value: "onboarding-sent", displayName: "Onboarding Sent" },
  onboardingComplete: { value: "onboarding-complete", displayName: "Onboarding Complete" },
  active: { value: "active", displayName: "Active" },
  inactive: { value: "inactive", displayName: "Inactive" },
};

export const PAYMENT_STATUS = {
  UNPAID: "unpaid",
  PAID: "paid",
};

export const defaultClientBookingValues = {
  serviceOrSubscription: "",
  startDate: null,
  startTime: null,
  endTime: null,
  isRecurring: false,
  recurrenceInterval: 1,
  recurrenceFrequency: "week",
  recurrenceDays: [],
  showEndDate: false,
  endDate: null,
};
