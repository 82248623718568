import PropTypes from "prop-types";
import CustomDialog from "../../common/CustomDialog";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AlertService from "../../common/Alert/alertService";
import { addNewClientSchema } from "../../../schemas/clientSchema";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import theme from "../../../theme";
import { clientTypeOptions } from "../../../constants/client.constants";
import { ClientActions, FormActions, SubscriptionsActions } from "../../api/RequestUtil";
import { ErrorMessage } from "../../../utils/commonUtils";

import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { SUCCESS } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";

const InitiateOnboardingModal = ({ open, handleClose, email }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [feedbackFormsOptions, setFeedbackFormsOptions] = useState([]);
  const { success, error } = AlertService();
  const [loader, setLoader] = useState({ getData: false, setData: false });
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(addNewClientSchema),
    defaultValues: {
      email,
      clientType: "",
      subscriptions: [],
      feedbackForms: [],
    },
  });

  const fetchSubscriptionsData = () => {
    setLoader((prev) => ({ ...prev, getData: true }));
    SubscriptionsActions.getSubscriptions()
      .then(({ data }) => {
        setSubscriptions(data.data);
      })
      .catch((data) => error(ErrorMessage(data)))
      .finally(() => setLoader((prev) => ({ ...prev, getData: false })));
  };

  const fetchFeedbackFormsData = () => {
    const payloadData = {
      type: "feedback",
    };
    FormActions.getFormsData(payloadData)
      .then(({ data }) => {
        setFeedbackFormsOptions(data.data);
      })
      .catch((err) => {
        error(ErrorMessage(err.response?.data));
      });
  };

  useEffect(() => {
    fetchSubscriptionsData();
    fetchFeedbackFormsData();
  }, []);

  const watchedSubscriptions = watch("subscriptions");
  const watchedFeedbackForms = watch("feedbackForms");

  const handleDeleteChip = (chipToDelete) => {
    const updatedSubscriptions = watchedSubscriptions.filter((sub) => sub !== chipToDelete);
    setFormValue("subscriptions", updatedSubscriptions);
  };

  const handleDeleteFeedbackChip = (chipToDelete) => {
    const updatedFeedbackForms = watchedFeedbackForms.filter((form) => form !== chipToDelete);
    setFormValue("feedbackForms", updatedFeedbackForms);
  };

  const onSubmit = (values) => {
    const payload = {
      email: values.email,
      client_type: values.clientType,
      subscription: values.subscriptions,
      feedback_form: values.feedbackForms,
    };
    setLoader((prev) => ({ ...prev, setData: true }));
    ClientActions.initiateNewLeadClient(payload)
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          success(data.message);
          reset();
          handleClose();
          navigate(-1);
        }
      })
      .catch(({ data }) => {
        error(ErrorMessage(data));
      })
      .finally(() => setLoader((prev) => ({ ...prev, setData: false })));
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      title="Initiate Onboarding"
      handleSave={handleSubmit(onSubmit)}
      loader={loader.setData}
    >
      {loader.getData ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="22vh">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Stack component="form" gap={2}>
          <FormControl fullWidth>
            <Select
              value={watch("clientType")}
              onChange={(e) => {
                setFormValue("clientType", e.target.value);
                trigger("clientType");
              }}
              error={!!errors.clientType}
              IconComponent={KeyboardArrowDownIcon}
              defaultValue=""
              displayEmpty
            >
              <MenuItem value="" disabled>
                <Typography
                  color={theme.palette.gray.light}
                  height="100%"
                  display="flex"
                  alignItems="center"
                  fontSize={16}
                >
                  Client Type
                </Typography>
              </MenuItem>
              {clientTypeOptions.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" color="error" sx={{ fontSize: 12, margin: "3px 14px 0px" }}>
              {errors.clientType?.message}
            </Typography>
          </FormControl>

          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              value={watchedSubscriptions}
              options={subscriptions?.filter((subs) => !watchedSubscriptions.includes(subs.id)).map((subs) => subs.id)}
              multiple
              getOptionLabel={(option) => {
                const subscription = subscriptions.find((subs) => subs.id === option);
                if (subscription) {
                  return subscription.name;
                }
                return "";
              }}
              sx={{
                "& .MuiSvgIcon-root": { display: "none !important", width: "0px !important" },
                "& .MuiAutocomplete-endAdornment": { display: "none !important" },
                "& .MuiOutlinedInput-root ": { padding: " 0px" },
                "& .MuiAutocomplete-inputRoot ": { padding: "0px" },
                "& .MuiAutocomplete-input": { minWidth: "387px !important" },
                "& .MuiInputBase-input ": { height: "27px !important" },
              }}
              onChange={(event, newValue) => {
                setFormValue("subscriptions", newValue);
                trigger("subscriptions");
              }}
              renderInput={(params) => (
                <TextField placeholder="Subscriptions" variant="outlined" fullWidth {...params} />
              )}
              renderTags={() => null}
            />
            <Typography variant="body2" color="error" sx={{ fontSize: 12, margin: "3px 14px 0px" }}>
              {errors.subscriptions?.message}
            </Typography>
            {watchedSubscriptions.length > 0 && (
              <Box
                height="150px"
                bgcolor={theme.palette.white.light}
                borderRadius="12px"
                padding={1}
                overflow="auto"
                marginTop={1}
              >
                {watchedSubscriptions?.map((sub) => {
                  const selectedSubscription = subscriptions.find((curr) => curr.id === sub);
                  return (
                    <Chip
                      key={sub}
                      label={selectedSubscription.name}
                      onDelete={() => handleDeleteChip(selectedSubscription.id)}
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        fontSize: "16px",
                        marginX: "2px",
                        marginY: "4px",
                        "& .MuiChip-deleteIcon": {
                          color: theme.palette.common.white,
                          ":hover": {
                            color: theme.palette.common.white,
                          },
                        },
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              value={watchedFeedbackForms}
              options={feedbackFormsOptions
                .filter((form) => !watchedFeedbackForms.includes(form.id))
                .map((form) => form.id)}
              multiple
              getOptionLabel={(option) => {
                const form = feedbackFormsOptions.find((curr) => curr.id === option);
                if (form) {
                  return form.name;
                }
                return "";
              }}
              sx={{
                "& .MuiSvgIcon-root": { display: "none !important", width: "0px !important" },
                "& .MuiAutocomplete-endAdornment": { display: "none !important" },
                "& .MuiOutlinedInput-root ": { padding: " 0px" },
                "& .MuiAutocomplete-inputRoot ": { padding: "0px" },
                "& .MuiAutocomplete-input": { minWidth: "387px !important" },
                "& .MuiInputBase-input ": { height: "27px !important" },
              }}
              onChange={(event, newValue) => {
                setFormValue("feedbackForms", newValue);
                trigger("feedbackForms");
              }}
              renderInput={(params) => (
                <TextField placeholder="Feedback Forms" variant="outlined" fullWidth {...params} />
              )}
              renderTags={() => null}
            />
            <Typography variant="body2" color="error" sx={{ fontSize: 12, margin: "3px 14px 0px" }}>
              {errors.feedbackForms?.message}
            </Typography>

            {watchedFeedbackForms.length > 0 && (
              <Box
                height="150px"
                bgcolor={theme.palette.white.light}
                borderRadius="12px"
                padding={1}
                overflow="auto"
                marginTop={1}
              >
                {watchedFeedbackForms?.map((formId) => {
                  const selectedForm = feedbackFormsOptions.find((form) => form.id === formId);
                  return (
                    selectedForm && (
                      <Chip
                        key={formId}
                        label={selectedForm.name}
                        onDelete={() => handleDeleteFeedbackChip(selectedForm.id)}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                          fontSize: "16px",
                          marginX: "2px",
                          marginY: "4px",
                          "& .MuiChip-deleteIcon": {
                            color: theme.palette.common.white,
                            ":hover": {
                              color: theme.palette.common.white,
                            },
                          },
                        }}
                      />
                    )
                  );
                })}
              </Box>
            )}
          </Grid>
        </Stack>
      )}
    </CustomDialog>
  );
};

InitiateOnboardingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default InitiateOnboardingModal;
