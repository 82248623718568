import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import theme from "../../../theme";
import { useSelector } from "react-redux";

const DynamicFieldContainer = ({
  children,
  label,
  onClick,
  onDelete,
  showDelete = true,
  onMoveUp,
  onMoveDown,
  currentIndex,
  showUpDownArrows = true,
}) => {
  const { dynamicFields } = useSelector((state) => state.form);
  const totalFields = dynamicFields?.length;

  return (
    <Box>
      <Box
        sx={{
          background: theme.palette.common.white,
          borderRadius: "12px",
          padding: onDelete ? "16px" : "0px",
          marginY: 1,
        }}
      >
        <Box display="flex" justifyContent="space-between" paddingX={onDelete ? 1 : 0} paddingBottom={1}>
          <Typography fontSize="16px" fontWeight="bold" sx={{ cursor: "pointer" }} onClick={onClick}>
            {label}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
            justifyContent="center"
            justifyItems="center"
          >
            {showUpDownArrows && (
              <Box display="flex" gap={1}>
                <NorthIcon
                  fontSize="small"
                  onClick={onMoveUp}
                  disabled={currentIndex === 0}
                  sx={{
                    cursor: currentIndex === 0 ? "not-allowed" : "pointer",
                  }}
                />
                <SouthIcon
                  fontSize="small"
                  onClick={onMoveDown}
                  disabled={currentIndex === totalFields - 1}
                  sx={{
                    cursor: currentIndex === totalFields - 1 ? "not-allowed" : "pointer",
                  }}
                />
              </Box>
            )}

            {showDelete && (
              <DeleteOutlineIcon color={theme.palette.primary.main} sx={{ cursor: "pointer" }} onClick={onDelete} />
            )}
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

DynamicFieldContainer.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  currentIndex: PropTypes.string,
  showUpDownArrows: PropTypes.bool,
};

export default DynamicFieldContainer;
