import { Avatar, Box, Chip, Typography } from "@mui/material";
import PropTypes from "prop-types";

import theme from "../../theme";

const ClientInfo = ({ label = "", icon = "" }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    <Box component="img" src={icon} height="24px" width="24px" />
    <Typography variant="body2" sx={{ fontSize: "14px", color: theme.palette.gray.medium }}>
      {label}
    </Typography>
  </Box>
);

const ClientDetailTile = ({ clientData }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
      <Avatar alt={clientData.name} src={clientData.image} sx={{ width: 72, height: 72 }} />
      <Box>
        {clientData.type && (
          <Typography variant="caption" sx={{ color: theme.palette.gray.medium, fontSize: "14px" }}>
            {clientData?.type}
          </Typography>
        )}

        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.charcoal, fontSize: "16px" }}>
            {clientData.name}
          </Typography>
          <Chip
            label={clientData?.status || "New Client"}
            sx={{
              backgroundColor: theme.palette.icedBlue,
              color: theme.palette.common.black,
              fontWeight: "500",
              fontSize: "12px",
              height: "24px",
              borderRadius: "12px",
              mt: 0.5,
            }}
            size="small"
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mt: 0.5 }}>
          {clientData?.sex && (
            <ClientInfo
              label={clientData.sex}
              icon={clientData.sex === "male" ? "/assets/images/GenderMale.svg" : "/assets/images/woman.svg"}
            />
          )}
          {clientData?.age && <ClientInfo label={clientData.age} icon={"/assets/images/cake.svg"} />}
        </Box>
      </Box>
    </Box>
  );
};

ClientInfo.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

ClientDetailTile.propTypes = {
  clientData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    sex: PropTypes.oneOf(["male", "female", "other"]).isRequired,
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export default ClientDetailTile;
