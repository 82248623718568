import { ThemeProvider } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";

import "./assets/styles/fonts.css";
import { AlertProvider } from "./components/common/Alert/AlertContext";
import Loader from "./components/route/Loader";
import PrivateRoute from "./components/route/PrivateRoute";
import theme from "./theme";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { ErrorBoundary } from "react-error-boundary";
import Error from "./components/Error";
import {
  AdminApproveExerciseRoute,
  AdminCreateFormRoute,
  AdminEditFormRoute,
  AdminFormsRoute,
  AdminLandingPageRoute,
  AdminServicesRoute,
  AdminSettingsRoute,
  AdminUsersRoute,
  AssessmentRoute,
  BlueprintsRoute,
  CalendarRoute,
  ClientProfileRoute,
  ClientsRoute,
  DashBoardRoute,
  DynamicBuilderEditRoute,
  DynamicBuilderRoute,
  ForgotPasswordRoute,
  LandingPageRoute,
  MarketHubRoute,
  OnBoardingRoute,
  ResetPasswordRoute,
  SignInRoute,
  SignUpRoute,
  TasksRoute,
  VerifyOTPRoute,
  SetPasswordRoute,
  ClientViewProgram,
  MessageRoute,
  CheckInFormDetailsRoute,
  ClientNewBlankSessionRoute,
  RecordSessionProgramWorkoutRoute,
  NewLeadClientRoute,
  ViewSessionDetailRoute,
  ViewSessionFormDetailRoute,
} from "./components/route/routes";
import NewLeadClientDetails from "./components/clients/new-lead-onboarding";

const Assessments = lazy(() => import("./components/assessments"));
const BluePrints = lazy(() => import("./components/bluePrints"));
const Dashboard = lazy(() => import("./components/dashboard"));
const DynamicBuilder = lazy(() => import("./components/dynamicBuilder"));
const EditDynamicBuilder = lazy(() => import("./components/dynamicBuilder/EditDynamicBuilder"));
const ForgotPassword = lazy(() => import("./components/onBoarding/ForgotPassword"));
const NotFound = lazy(() => import("./components/route/NotFound"));
const OnBoarding = lazy(() => import("./components/onBoarding"));
const ResetPassword = lazy(() => import("./components/onBoarding/ResetPassword"));
const SignIn = lazy(() => import("./components/signIn"));
const SignUp = lazy(() => import("./components/onBoarding/SignUp"));
const Users = lazy(() => import("./components/users"));
const Clients = lazy(() => import("./components/clients"));
const ClientProfile = lazy(() => import("./components/clients/clientProfile"));
const Forms = lazy(() => import("./components/forms"));
const CreateForm = lazy(() => import("./components/forms/createForm"));
const EditForm = lazy(() => import("./components/forms/EditForm"));
const ServicesAndSubscriptions = lazy(() => import("./components/services-and-subscriptions"));
const Settings = lazy(() => import("./components/settings"));
const Tasks = lazy(() => import("./components/tasks"));
const MarketHub = lazy(() => import("./components/marketHub"));
const AdminLandingPage = lazy(() => import("./components/landing-page/admin-landing-page"));
const LandingPage = lazy(() => import("./components/landing-page"));
const Calendar = lazy(() => import("./components/calendar"));
const ApproveExercise = lazy(() => import("./components/approve-exercise"));
const VerifyOTP = lazy(() => import("./components/onBoarding/VerifyOTP"));
const SetPassword = lazy(() => import("./components/onBoarding/SetPassword"));
const ViewProgram = lazy(() => import("./components/clients/clientProfile/view-program"));
const Messages = lazy(() => import("./components/messages"));
const ClientCheckInFormDetails = lazy(() => import("./components/forms/ClientCheckInFormDetails"));
const CreateNewBlankSession = lazy(() => import("./components/clients/clientProfile/blank-session"));
const RecordSessionProgramWorkout = lazy(
  () => import("./components/clients/clientProfile/record-session-program-workout")
);
const ViewSessionDetails = lazy(() => import("./components/clients/clientProfile/session-notes/ViewSessionDetails"));
const SessionFeedbackFormDetails = lazy(() => import("./components/forms/SessionFeedbackFormDetails"));

const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={Error}>
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  </ErrorBoundary>
);

const App = () => {
  const router = createBrowserRouter([
    {
      element: <ErrorBoundaryLayout />,
      children: [
        {
          path: "/",
          element: <PrivateRoute />,
          children: [
            {
              path: "/",
              element: <Navigate to={SignInRoute} replace />,
            },
            {
              path: SignInRoute,
              element: <SignIn />,
            },
            {
              path: SignUpRoute,
              element: <SignUp />,
            },
            {
              path: ForgotPasswordRoute,
              element: <ForgotPassword />,
            },
            {
              path: ResetPasswordRoute,
              element: <ResetPassword />,
            },
            {
              path: VerifyOTPRoute,
              element: <VerifyOTP />,
            },
            {
              path: SetPasswordRoute,
              element: <SetPassword />,
            },
            {
              path: DashBoardRoute,
              element: <Dashboard />,
            },
            {
              path: OnBoardingRoute,
              element: <OnBoarding />,
            },
            {
              path: ClientsRoute,
              element: <Clients />,
            },
            {
              path: ClientProfileRoute,
              element: <ClientProfile />,
            },
            {
              path: ClientViewProgram,
              element: <ViewProgram />,
            },
            {
              path: ClientNewBlankSessionRoute,
              element: <CreateNewBlankSession />,
            },
            {
              path: RecordSessionProgramWorkoutRoute,
              element: <RecordSessionProgramWorkout />,
            },
            {
              path: DynamicBuilderRoute,
              element: <DynamicBuilder />,
            },
            {
              path: DynamicBuilderEditRoute,
              element: <EditDynamicBuilder />,
            },
            {
              path: BlueprintsRoute,
              element: <BluePrints />,
            },
            {
              path: AdminUsersRoute,
              element: <Users />,
            },
            {
              path: AdminFormsRoute,
              element: <Forms />,
            },
            {
              path: AdminCreateFormRoute,
              element: <CreateForm />,
            },
            {
              path: AdminEditFormRoute,
              element: <EditForm />,
            },
            {
              path: AdminServicesRoute,
              element: <ServicesAndSubscriptions />,
            },
            {
              path: AssessmentRoute,
              element: <Assessments />,
            },
            {
              path: AdminSettingsRoute,
              element: <Settings />,
            },
            {
              path: TasksRoute,
              element: <Tasks />,
            },
            {
              path: MarketHubRoute,
              element: <MarketHub />,
            },
            {
              path: AdminLandingPageRoute,
              element: <AdminLandingPage />,
            },
            {
              path: CalendarRoute,
              element: <Calendar />,
            },
            {
              path: MessageRoute,
              element: <Messages />,
            },
            {
              path: CheckInFormDetailsRoute,
              element: <ClientCheckInFormDetails />,
            },
            {
              path: NewLeadClientRoute,
              element: <NewLeadClientDetails />,
            },
            {
              path: ViewSessionDetailRoute,
              element: <ViewSessionDetails />,
            },
            {
              path: ViewSessionFormDetailRoute,
              element: <SessionFeedbackFormDetails />,
            },
          ],
        },
        {
          path: LandingPageRoute,
          element: <LandingPage />,
        },
        {
          path: AdminApproveExerciseRoute,
          element: <ApproveExercise />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  const [alertMessage, setAlertMessage] = useState({ body: "", title: "" });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      setAlertMessage({
        body: payload.notification.body,
        title: payload.notification.title,
      });
      setShowAlert(true);

      setTimeout(() => setShowAlert(false), 6000);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        {showAlert && (
          <Alert
            severity="info"
            onClose={() => setShowAlert(false)}
            style={{ position: "fixed", top: 10, right: 10, zIndex: 1000 }}
          >
            <AlertTitle>{alertMessage.title}</AlertTitle>
            {alertMessage.body}
          </Alert>
        )}
        <RouterProvider router={router} />
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;
