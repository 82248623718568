import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const TextFieldWithLabel = ({ placeholder, disabled = false, value = "" }) => (
  <TextField
    fullWidth
    placeholder={placeholder}
    value={value}
    variant="outlined"
    disabled={disabled}
    sx={{ marginBottom: "8px" }}
  />
);

TextFieldWithLabel.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default TextFieldWithLabel;
