import { Box, Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";

import theme from "../../theme";

const BuilderHeading = ({ heading, onBackClick }) => {
  return (
    <Box>
      <Box height="92px" display="flex" alignItems="center">
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          justifyItems="center"
          height="95px"
          width="100%"
        >
          {onBackClick && (
            <Box
              component="img"
              src="/assets/images/BackToPreviousPage.svg"
              alt="back icon"
              onClick={onBackClick}
              sx={{
                cursor: "pointer",
                width: 24,
                height: 24,
              }}
            />
          )}
          <Typography fontSize="22px" fontWeight={"500"}>
            {heading}
          </Typography>
        </Box>
      </Box>
      <Divider
        variant="fullWidth"
        sx={{
          bgcolor: theme.palette.common.white,
          height: "1px",
          width: "100%",
        }}
      />
    </Box>
  );
};

BuilderHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
};

export default BuilderHeading;
