import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClientActions } from "../../api/RequestUtil";
import AlertService from "../../common/Alert/alertService";
import { ErrorMessage } from "../../../utils/commonUtils";
import { SUCCESS } from "../../../utils/constants";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import BuilderHeading from "../../common/BuilderHeading";
import ClientDetailTile from "../../common/ClientDetailTile";
import theme from "../../../theme";
import { useDispatch } from "react-redux";
import { setFormData } from "../../../redux/features/formSlice";
import InitiateOnboardingModal from "./InitiateOnboardingModal";
import DynamicFormFields from "../../forms/createForm/DynamicFormFields";

const NewLeadClientDetails = () => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { error } = AlertService();
  const navigate = useNavigate();
  const [clientData, setClientData] = useState({});
  const [viewForm, setViewForm] = useState(false);
  const [formName, setFormName] = useState("General Enquiry Form");
  const [initiateOnboarding, setInitialOnboarding] = useState(false);

  const handleViewForm = () => {
    setViewForm(!viewForm);
  };

  const handleInitiateOnboarding = () => setInitialOnboarding(!initiateOnboarding);

  const fetchNewLeadClientDetails = () => {
    setLoader(true);
    ClientActions.getNewLeadClientDetails(clientId)
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          if (data.data.clientServiceForm) {
            setFormName(`${data.data.clientServiceForm} Enquiry Form`);
          }
          const personalInformation = data.data.clientDetails;
          const transformedData = {
            name: personalInformation.client_name,
            image: personalInformation.profile_image,
            status: personalInformation.status,
            age: personalInformation.age,
            sex: personalInformation.sex || "-",
            email: personalInformation.email,
          };
          setClientData(transformedData);

          const transformedArray = data.data.formResponses.map((response) => ({
            ...response,
            ...response.formField,
          }));

          dispatch(
            setFormData({
              formData: {
                name: "",
                description: "",
                type: "",
                priority: "",
                view_only: "",
                transfer_to_client_record_fields: false,
                form_fields: transformedArray,
              },
            })
          );
        }
      })
      .catch((data) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    fetchNewLeadClientDetails();
  }, []);

  if (loader) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Stack gap={2}>
      <BuilderHeading heading="Client Profile" onBackClick={() => navigate(-1)} />
      <ClientDetailTile clientData={clientData} />
      <Stack
        sx={{ background: theme.palette.white.light, borderRadius: "12px", height: "auto" }}
        gap={2}
        padding="12px"
      >
        <Stack
          justifyContent="center"
          paddingLeft={1}
          sx={{ background: theme.palette.common.white, borderRadius: "12px", height: "48px", textAlign: "start" }}
        >
          <Typography fontSize="16px">
            email : <strong>{clientData.email}</strong>
          </Typography>
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          padding="12px"
          onClick={handleViewForm}
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: "12px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ background: theme.palette.common.white, fontWeight: "700" }}>{formName}</Box>
          <Box
            component="img"
            width="24px"
            height="24px"
            src={viewForm ? "/assets/images/arrow-up.svg" : "/assets/images/arrow-down.svg"}
            sx={{ cursor: "pointer" }}
          />
        </Stack>
        {viewForm && (
          <Box>
            <DynamicFormFields showDelete={false} showUpDownArrows={false} />
          </Box>
        )}
      </Stack>

      <Button variant="contained" sx={{ width: "340px", height: "70px" }} onClick={handleInitiateOnboarding}>
        Initiate Onboarding
      </Button>

      {initiateOnboarding && (
        <InitiateOnboardingModal
          open={initiateOnboarding}
          handleClose={handleInitiateOnboarding}
          email={clientData.email}
        />
      )}
    </Stack>
  );
};

export default NewLeadClientDetails;
