import { createSlice } from "@reduxjs/toolkit";
import {
  EXERCISE_ID,
  IS_PARENT,
  LINKED_TO,
  ROW_ID,
  SET_STRUCTURE,
  TABLE_HEADER_DROPDOWN_OPTIONS,
} from "../../constants/dynamicBuilder.constants";

const initialTabValue = {
  warmUp: false,
  cooldown: false,
  deload: false,
  weeks: [],
  warmUpText: "",
  cooldownText: "",
};

const getInitialValuesForEnabledVariables = () => TABLE_HEADER_DROPDOWN_OPTIONS.map((option) => option.value);

const initialState = {
  activeTab: 0,
  blueprintId: null,
  name: "",
  description: "",
  type: "",
  feedback: "",
  showLeavePopUp: false,
  tabs: [{ name: "Workout 1", ...initialTabValue, rows: [] }],
  duration: 1,
  isRowAdd: null,
  isRowRemove: null,
  isUnlinkRow: null,
  showUnlinkButton: false,
  exercises: [],
  enabledVariables: getInitialValuesForEnabledVariables(),
  columnsDef: [],
  columnsOrder: [],
};
const updateColumnsDef = (columnsDef, workoutDetails) => {
  const updatedColumnsDef = [...columnsDef];

  workoutDetails.forEach((detail) => {
    TABLE_HEADER_DROPDOWN_OPTIONS.forEach((option) => {
      if (detail[option.value] !== null && detail[option.value] !== undefined) {
        const columnExists = updatedColumnsDef.find((column) => column.value === option.value);
        if (!columnExists) {
          updatedColumnsDef.push({
            displayName: option.displayName,
            value: option.value,
          });
        }
      }
    });
  });

  return updatedColumnsDef;
};

const formatWorkout = (workout) => {
  const formattedWeeks = workout.weeks.map(formatWeek);
  return {
    ...initialTabValue,
    name: workout.name,
    warmUp: workout?.warm_up || false,
    cooldown: workout.cool_down || false,
    deload: (workout?.deload_week_based_on && workout?.deload_week_number) || false,
    ...(workout?.warm_up && { warmUpText: workout?.warm_up }),
    ...(workout?.cool_down && { cooldownText: workout?.cool_down }),
    ...(workout?.deload_week_based_on && { deloadWeekFrom: workout?.deload_week_based_on }),
    ...(workout?.deload_week_number && { deloadWeekTo: workout?.deload_week_number }),
    weeks: formattedWeeks,
    rows: workout?.rows,
  };
};

const formatWeek = (week) => {
  const formattedRows = week.workoutDetails.map((row) => formatRow(row, week.workoutDetails));
  return {
    week_number: week?.week_number,
    rows: formattedRows,
  };
};

const formatRow = (row, workoutDetails) => {
  const isParent = workoutDetails.findIndex((workoutRow) => workoutRow.linked_to === row.row_id) !== -1;

  const updatedRow = { ...row, id: row.row_id, isParent };

  return Object.fromEntries(Object.entries(updatedRow).filter(([, value]) => value !== null));
};

const getAllWorkoutDetails = (workouts) =>
  workouts.flatMap((workout) => workout.weeks.flatMap((week) => week.workoutDetails));

const filterWorkoutRowData = (row) =>
  Object.keys(row)
    .filter((key) => [SET_STRUCTURE, EXERCISE_ID, "id", LINKED_TO, IS_PARENT].includes(key))
    .reduce((newRow, key) => {
      newRow[key] = row[key];
      return newRow;
    }, {});

const processRowData = (row) =>
  Object.keys(row)
    .filter((key) => ![SET_STRUCTURE, EXERCISE_ID, "id", LINKED_TO, IS_PARENT].includes(key))
    .reduce((newRow, key) => {
      newRow[key] = row[key];
      return newRow;
    }, {});

const filterAndRenameKeys = (row) =>
  Object.keys(row).reduce((newRow, key) => {
    if ([SET_STRUCTURE, EXERCISE_ID, ROW_ID, LINKED_TO, IS_PARENT].includes(key)) {
      newRow[key === ROW_ID ? "id" : key] = row[key];
    }
    return newRow;
  }, {});

const dynamicBuilderSlice = createSlice({
  name: "dynamicBuilder",
  initialState,
  reducers: {
    addTab: (state, action) => {
      state.tabs.push({ ...initialTabValue, name: action.payload });
      state.activeTab = state.tabs.length - 1;
    },
    editTab: (state, action) => {
      const { index, newName } = action.payload;
      if (state.tabs[index]) {
        state.tabs[index].name = newName;
      }
    },
    removeTab: (state, action) => {
      const index = action.payload;
      state.tabs.splice(index, 1);
      if (state.activeTab >= state.tabs.length) {
        state.activeTab = Math.max(state.tabs.length - 1, 0);
      }
    },
    setAddRow: (state, action) => {
      state.isRowAdd = action.payload;
    },
    setRemoveRow: (state, action) => {
      state.isRowRemove = action.payload;
    },
    setUnLinkRow: (state, action) => {
      state.isUnlinkRow = action.payload;
    },
    setShowUnlinkButton: (state, action) => {
      state.showUnlinkButton = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setProgramName: (state, action) => {
      state.name = action.payload;
    },
    setProgramDescription: (state, action) => {
      state.description = action.payload;
    },
    setProgramType: (state, action) => {
      state.type = action.payload;
    },
    setProgramFeedback: (state, action) => {
      state.feedback = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setShowLeavePopUp: (state) => {
      state.showLeavePopUp = !state.showLeavePopUp;
    },
    setExercises: (state, action) => {
      state.exercises = action.payload;
    },
    toggleCooldown: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].cooldown = !state.tabs[currentTab].cooldown;
    },
    setCooldownText: (state, action) => {
      state.tabs[state.activeTab].cooldownText = action.payload;
    },
    toggleWarmUp: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].warmUp = !state.tabs[currentTab].warmUp;
    },
    setWarmUpText: (state, action) => {
      state.tabs[state.activeTab].warmUpText = action.payload;
    },
    sliceWeekData: (state, action) => {
      const newDuration = action.payload?.newDuration;

      const newTabs = state.tabs?.map((tab) => ({
        ...tab,
        weeks: tab.weeks.slice(0, newDuration),
      }));

      return {
        ...state,
        tabs: newTabs,
      };
    },
    toggleDeload: (state) => {
      const currentTab = state.activeTab;
      state.tabs[currentTab].deload = !state.tabs[currentTab].deload;
      const toIndex = parseInt(state.tabs[currentTab].deloadWeekTo, 10) - 1;
      if (state.tabs[currentTab].weeks[toIndex]) {
        state.tabs[currentTab].weeks[toIndex] = {
          week_number: toIndex + 1,
          rows: [],
        };
      }
      state.tabs[currentTab].deloadWeekFrom = "";
      state.tabs[currentTab].deloadWeekTo = "";
    },
    setDeloadValue: (state, action) => {
      const { weekTo, weekFrom } = action.payload;
      const currentTab = state.activeTab;
      if (state.tabs[currentTab]) {
        state.tabs[currentTab].deloadWeekTo = weekTo;
        state.tabs[currentTab].deloadWeekFrom = weekFrom;
        const fromIndex = parseInt(weekFrom) - 1;
        const toIndex = parseInt(weekTo) - 1;
        if (state.tabs[currentTab].weeks[fromIndex] && fromIndex < toIndex) {
          const weekFromData = { ...state.tabs[currentTab].weeks[fromIndex] };
          if (!state.tabs[currentTab].weeks[toIndex]) {
            state.tabs[currentTab].weeks[toIndex] = {
              week_number: toIndex + 1,
              rows: [],
            };
          }

          state.tabs[currentTab].weeks[toIndex] = {
            ...weekFromData,
            week_number: toIndex + 1,
          };
        }
      }
    },
    setWorkoutRows: (state, action) => {
      const rowData = action.payload;

      const newTabs = state.tabs?.map((tab, index) => {
        if (index === state.activeTab) {
          return {
            ...tab,
            rows: rowData.map((row) => filterWorkoutRowData(row)),
          };
        }

        return tab;
      });

      return {
        ...state,
        tabs: newTabs,
      };
    },
    addTableData: (state, action) => {
      const { weekIndex, rowData } = action.payload;

      const newTabs = state.tabs.map((tab, index) => {
        if (index === state.activeTab) {
          const updatedWeeks = [...tab.weeks];
          if (weekIndex >= updatedWeeks.length) {
            updatedWeeks.length = weekIndex + 1;
          }

          updatedWeeks[weekIndex] = {
            week_number: updatedWeeks[weekIndex]?.week_number || weekIndex + 1,
            rows: rowData.map((row) => processRowData(row)),
          };

          return { ...tab, weeks: updatedWeeks, rows: rowData.map((row) => filterWorkoutRowData(row)) };
        }
        return tab;
      });

      return {
        ...state,
        tabs: newTabs,
      };
    },
    removeColumn: (state, action) => {
      const { columnField } = action.payload;

      const newTabs = state.tabs.map((tab) => ({
        ...tab,
        weeks: tab.weeks.map((week) => ({
          ...week,
          rows: week.rows.map((row) => {
            const newRow = { ...row };
            delete newRow[columnField];
            return newRow;
          }),
        })),
      }));

      return {
        ...state,
        tabs: newTabs,
      };
    },
    setEnabledVariables: (state, action) => {
      const { value } = action.payload;

      const currEnabledVariable = [...state.enabledVariables];

      const isValuePresent = currEnabledVariable.includes(value);

      const updatedEnabledVariables = isValuePresent
        ? currEnabledVariable.filter((variable) => variable !== value)
        : [...currEnabledVariable, value];

      state.enabledVariables = updatedEnabledVariables;
    },
    setProgramData: (state, action) => {
      const { id, program } = action.payload.blueprint;
      const { name, description, type, feedback_form, duration, workouts } = program;

      const addCommonRowsToWorkout = workouts?.map((workout) => {
        const rows = workout.weeks[0].workoutDetails.map(filterAndRenameKeys);

        return {
          ...workout,
          rows,
        };
      });

      const formattedTabs = addCommonRowsToWorkout?.map(formatWorkout);
      const allWorkoutDetails = getAllWorkoutDetails(workouts);

      state.columnsDef = updateColumnsDef([], allWorkoutDetails);
      state.blueprintId = id;
      state.name = name;
      state.description = description;
      state.type = type;
      state.feedback = feedback_form;
      state.duration = duration;
      state.tabs = formattedTabs;
      state.activeTab = 0;
      state.showLeavePopUp = true;
    },
    setColumnsOrder: (state, action) => {
      state.columnsOrder = action.payload;
    },
    resetDynamicBuilder: () => initialState,
  },
});

export const {
  addTab,
  editTab,
  removeTab,
  setActiveTab,
  toggleCooldown,
  toggleWarmUp,
  toggleDeload,
  setDuration,
  setProgramName,
  setProgramDescription,
  setProgramType,
  setProgramFeedback,
  setCooldownText,
  setWarmUpText,
  setShowLeavePopUp,
  setDeloadValue,
  setExercises,
  addTableData,
  setAddRow,
  setRemoveRow,
  setUnLinkRow,
  setShowUnlinkButton,
  removeColumn,
  setEnabledVariables,
  setWorkoutRows,
  setProgramData,
  sliceWeekData,
  setColumnsOrder,
  resetDynamicBuilder,
} = dynamicBuilderSlice.actions;

export default dynamicBuilderSlice.reducer;
