import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Radio, Select, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteDynamicFormField,
  setActiveTabIndex,
  setFreeTextField,
  setSelectedFormField,
  moveDynamicFormFields,
} from "../../../redux/features/formSlice";
import theme from "../../../theme";
import { debounce } from "../../../utils/commonUtils";
import DynamicFieldContainer from "./DynamicFieldContainer";
import TextFieldWithLabel from "./TextFieldWithLabel";
import { CHAR_LIMIT } from "../../../constants/constant";

const isFreeTextField = (field) => field?.fieldTag?.toLowerCase().includes("freetext");

const DynamicFormFields = ({ showDelete, showUpDownArrows }) => {
  const dispatch = useDispatch();
  const { dynamicFields } = useSelector((state) => state.form);
  const { dateFormat } = useSelector((state) => state.userSettings);

  const moveItem = (array, fromIndex, toIndex) => {
    const updatedArray = [...array];
    const [removedItem] = updatedArray.splice(fromIndex, 1);
    updatedArray.splice(toIndex, 0, removedItem);
    return updatedArray;
  };

  const handleMove = (currentIndex, direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < dynamicFields.length) {
      const updatedFields = moveItem(dynamicFields, currentIndex, newIndex);
      dispatch(moveDynamicFormFields(updatedFields));
    }
  };

  const [freeTextValues, setFreeTextValues] = useState([]);

  const handleDelete = (fieldTag) => {
    dispatch(deleteDynamicFormField(fieldTag));
  };

  const handleFieldClick = (field) => {
    dispatch(setActiveTabIndex(1));
    dispatch(setSelectedFormField({ ...field, isEdit: true }));

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const debouncedUpdateFieldValue = useCallback(
    debounce((fieldTag, value) => {
      dispatch(setFreeTextField({ fieldTag, defaultValue: value }));
    }, 500),
    [dispatch]
  );

  const handleChange = (fieldTag, value) => {
    setFreeTextValues((prevFreeTextValues) => {
      const updatedValues = prevFreeTextValues.map((freeTextValue) =>
        freeTextValue.fieldTag === fieldTag ? { ...freeTextValue, defaultValue: value } : freeTextValue
      );

      const exists = updatedValues.some((freeTextValue) => freeTextValue.fieldTag === fieldTag);

      if (!exists) {
        return [...updatedValues, { fieldTag, defaultValue: value }];
      }

      return updatedValues;
    });

    debouncedUpdateFieldValue(fieldTag, value);
  };

  const renderTextField = (dynamicField, currentValue, commonProps, index) => {
    const charsRemaining = dynamicField.fieldValue
      ? CHAR_LIMIT - (dynamicField?.fieldValue?.length || 0)
      : CHAR_LIMIT - (currentValue?.length || 0);

    return (
      <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          placeholder="Description"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "0px",
              "& .MuiInputBase-input": {
                height: "321px !important",
                padding: "16px 10px !important",
              },
            },
          }}
          value={dynamicField?.fieldValue || currentValue}
          disabled={!dynamicField?.readOnly}
          onChange={(e) => handleChange(dynamicField.fieldTag, e.target.value)}
          inputProps={{ maxLength: CHAR_LIMIT }}
        />
        <Typography
          variant="body2"
          sx={{ textAlign: "right", marginTop: theme.spacing(1), fontSize: "12px", paddingBottom: theme.spacing(1) }}
          color={charsRemaining < 10 ? theme.palette.danger : theme.palette.gray.light}
        >
          {charsRemaining} characters remaining
        </Typography>
      </DynamicFieldContainer>
    );
  };

  const renderAddressField = (dynamicField, commonProps, index) => (
    <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
      <Grid container columnSpacing={2} rowSpacing={0.5}>
        {dynamicField.fields.map((field) => {
          const gridSize = ["City", "State", "Post Code"].includes(field.fieldLabel) ? 4 : 12;
          return (
            <Grid item xs={gridSize} key={field.fieldTag}>
              <TextFieldWithLabel fullWidth placeholder={field.fieldLabel} value={field?.fieldValue} />
            </Grid>
          );
        })}
      </Grid>
    </DynamicFieldContainer>
  );

  const renderRangeField = (dynamicField, commonProps, index) => {
    const [start, end] = dynamicField?.fieldOptions?.[0]?.split("-").map(Number) || [];
    const rangeNumbers = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    const fieldValue = Number(dynamicField?.fieldValue);

    return (
      <DynamicFieldContainer
        {...commonProps}
        key={dynamicField.fieldTag}
        label={dynamicField.fieldLabel}
        currentIndex={index}
      >
        <Box display="flex" justifyContent="space-between" paddingX={1}>
          {rangeNumbers.map((num) => (
            <Box
              key={num}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "12px",
                backgroundColor: num === fieldValue ? theme.palette.primary.main : theme.palette.white.light,
                fontSize: "16px",
                fontWeight: "bold",
                color: num === fieldValue ? theme.palette.common.white : theme.palette.gray.light,
              }}
            >
              {num}
            </Box>
          ))}
        </Box>
      </DynamicFieldContainer>
    );
  };

  const renderSelectField = (dynamicField, commonProps, index) => (
    <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
      <Select
        IconComponent={KeyboardArrowDownIcon}
        displayEmpty
        disabled
        fullWidth
        value={dynamicField?.fieldValue || ""}
      >
        <MenuItem value={dynamicField?.fieldValue || ""} disabled>
          <Typography color={theme.palette.gray.light} height="100%" display="flex" alignItems="center" fontSize={16}>
            {dynamicField?.fieldValue || "Select an option"}
          </Typography>
        </MenuItem>
      </Select>
    </DynamicFieldContainer>
  );

  const renderDateField = (dynamicField, commonProps, index) => (
    <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format={dateFormat.toUpperCase()}
          placeholder="Controlled picker"
          sx={{ width: "100% !important" }}
          disabled
          value={dayjs(dynamicField?.fieldValue)}
        />
      </LocalizationProvider>
    </DynamicFieldContainer>
  );

  const renderChoiceField = (dynamicField, commonProps, index) => {
    const Control = dynamicField.fieldType === "checkbox" ? Checkbox : Radio;
    let fieldValue = dynamicField.fieldValue;
    try {
      if (dynamicField.fieldType === "checkbox" && fieldValue) {
        fieldValue = JSON.parse(fieldValue);
      } else if (typeof fieldValue === "string") {
        fieldValue = fieldValue.trim();
      }
    } catch (e) {
      if (typeof fieldValue === "string") {
        fieldValue = fieldValue.split(",").map((val) => val.trim());
      } else {
        fieldValue = [];
      }
    }

    return (
      <DynamicFieldContainer
        {...commonProps}
        key={dynamicField.fieldTag}
        label={dynamicField.fieldLabel}
        currentIndex={index}
      >
        <Box display="flex" flexDirection="row" paddingX={1}>
          {dynamicField.fieldOptions?.map((option) => {
            const isChecked =
              dynamicField.fieldType === "checkbox"
                ? Array.isArray(fieldValue) && fieldValue.includes(option)
                : fieldValue === option;

            return (
              <FormControlLabel
                key={option.value}
                control={<Control color="primary" disabled />}
                checked={isChecked}
                label={
                  <Typography fontSize="14px" fontWeight="medium" color={theme.palette.text.primary}>
                    {option}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      </DynamicFieldContainer>
    );
  };

  return (
    <Box
      sx={{
        background: theme.palette.white.light,
        borderRadius: "12px",
        padding: 1,
        minHeight: "280px",
      }}
    >
      {dynamicFields?.map((dynamicField, index) => {
        const commonProps = {
          key: dynamicField.fieldTag,
          label: dynamicField.fieldLabel || dynamicField.defaultFieldLabel,
          onClick: () => handleFieldClick(dynamicField),
          onDelete: () => handleDelete(dynamicField.fieldTag),
          onMoveUp: () => handleMove(index, -1),
          onMoveDown: () => handleMove(index, 1),
          ...(!showDelete && { showDelete }),
          ...(!showUpDownArrows && { showUpDownArrows }),
        };

        const currentValue =
          freeTextValues?.find((field) => field.fieldTag === dynamicField.fieldTag)?.defaultValue ||
          dynamicField.defaultValue ||
          "";

        if (isFreeTextField(dynamicField)) {
          return renderTextField(dynamicField, currentValue, commonProps, index);
        }

        switch (dynamicField.fieldType) {
          case "group":
            if (dynamicField?.fieldTag === "Address") return renderAddressField(dynamicField, commonProps, index);
            break;
          case "text":
          case "number":
            return (
              <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
                <TextFieldWithLabel placeholder="Your text goes here" value={dynamicField?.fieldValue} />
              </DynamicFieldContainer>
            );
          case "range":
            return renderRangeField(dynamicField, commonProps, index);
          case "dropdown":
            return renderSelectField(dynamicField, commonProps, index);
          case "date":
            return renderDateField(dynamicField, commonProps, index);
          case "checkbox":
          case "radio":
            return renderChoiceField(dynamicField, commonProps, index);
          case "file":
            return (
              <DynamicFieldContainer {...commonProps} key={dynamicField.fieldTag} currentIndex={index}>
                <Box
                  paddingX={1}
                  bgcolor={theme.palette.white.light}
                  height="40px"
                  borderRadius="12px"
                  alignItems="center"
                  display="flex"
                  gap={2}
                >
                  <Box
                    component="img"
                    src="/assets/images/signatureIcon.svg"
                    alt="signature icon"
                    sx={{ cursor: "pointer" }}
                  />
                  {dynamicField?.fieldValue && (
                    <Box
                      width="100%"
                      height="30px"
                      sx={{
                        backgroundImage: `url(${dynamicField?.fieldValue})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                      }}
                    />
                  )}
                </Box>
              </DynamicFieldContainer>
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};

DynamicFormFields.propTypes = {
  showDelete: PropTypes.bool,
  showUpDownArrows: PropTypes.bool,
};

export default DynamicFormFields;
