import { useAlert } from "./AlertContext";

const AlertService = () => {
  const { showAlert } = useAlert();

  return {
    success: (message) => showAlert(message, "success"),
    error: (message) => showAlert(message, "error"),
    info: (message) => showAlert(message, "info"),
    warning: (message) => showAlert(message, "warning"),
  };
};

export default AlertService;
